<template>
  <div class="page-theatre">
    <div class="theatre-suggest">
      <div class="banner-layer">
        <div class="theatre-suggest-header">
          <h1 class="banner-main-title"><strong>Онцлох</strong> контентууд</h1>
          <div class="carousel-actions">
            <a href="javascript:void(0)" @click="prev"><i class="isax isax-arrow-left"></i></a>
            <a href="javascript:void(0)" @click="next"><i class="isax isax-arrow-right-1"></i></a>
          </div>
        </div>

        <div class="carousel-wrapper">
          <Carousel class="hidden lg:flex" ref="carousel" :itemsToShow="5"  :wrapAround="false" :transition="500">
            <Slide v-for="item in suggestedMedias" :key="item.anime_id">
              <div class="suggest-item">
                <router-link :to="`/theatre/detail/${item.anime_id}`" class="suggest-cover">
                  <a href="javascript:void(0)" class="card__play">
                    <i class="isax isax-play"></i>
                  </a>
                </router-link>

                <!--                <span class="card__rate card__rate&#45;&#45;green">{{ item.rate ? item.rate : '0.0' }}</span>-->

                <img :src="Poster" v-lazy:background-image="imageUrl(item.image, true)" class="poster rounded-md"/>
                <div class="suggest-item-info">
                  <router-link to="/detail" class="line-clamp-1">{{ item.title }}</router-link>
                </div>
              </div>
            </Slide>
          </Carousel>
          <Carousel class="hidden md:flex lg:hidden" ref="carousel" :itemsToShow="3" :wrapAround="false" :transition="500">
            <Slide v-for="item in suggestedMedias" :key="item.anime_id">
              <div class="suggest-item">
                <router-link :to="`/theatre/detail/${item.anime_id}`" class="suggest-cover">
                  <a href="javascript:void(0)" class="card__play">
                    <i class="isax isax-play"></i>
                  </a>
                </router-link>

                <img :src="Poster" v-lazy:background-image="imageUrl(item.image, true)" class="poster rounded-md"/>
                <div class="suggest-item-info">
                  <router-link to="/detail" class="line-clamp-1">{{ item.title }}</router-link>
                </div>
              </div>
            </Slide>
          </Carousel>
          <Carousel class="flex md:hidden lg:hidden" ref="carousel" :itemsToShow="2" :wrapAround="false" :transition="500">
            <Slide v-for="item in suggestedMedias" :key="item.anime_id">
              <div class="suggest-item">
                <router-link :to="`/theatre/detail/${item.anime_id}`" class="suggest-cover">
                  <a href="javascript:void(0)" class="card__play">
                    <i class="isax isax-play"></i>
                  </a>
                </router-link>

                <img :src="Poster" v-lazy:background-image="imageUrl(item.image, true)" class="poster rounded-md"/>
                <div class="suggest-item-info">
                  <router-link to="/detail" class="line-clamp-1">{{ item.title }}</router-link>
                </div>
              </div>
            </Slide>
          </Carousel>
        </div>
      </div>
    </div>

    <div class="page-theatre-body p-6">
      <Section title="Шинээр нэмэгдсэн" action="getLatestEpisodes" getter="latestEpisodes" moreLink="/episode/new"/>
      <SectionContainer action="getHomeCatMedias" getter="homeCatMedias"/>
    </div>
  </div>
</template>

<script>
import {Carousel, Slide} from 'vue3-carousel'
import Poster from "../../assets/poster-hz.png";
import {imageUrl} from "../../utils";
import {mapGetters} from 'vuex';
import SectionContainer from "@components/SectionContainer.vue";
import Section from "@components/Section.vue";

export default {
  components: {
    Carousel,
    Slide,
    Section,
    SectionContainer
  },
  data() {
    return {
      Poster,
      isLoadingSuggest: true,
      isTypes: false,
    }
  },
  computed: {
    ...mapGetters(['suggestedMedias']),
  },
  created() {
    this.$store.dispatch('getSuggestedMedia', {vm: this, cat: 1})
  },
  methods: {
    imageUrl,
    next() {
      this.$refs.carousel.next()
    },
    prev() {
      this.$refs.carousel.prev()
    },
    showActorsModal() {
      this.isTypes = true;
    },

    showActorsModalHide() {
      this.isTypes = false;
    },
  }
};
</script>